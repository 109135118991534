.root {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  padding: 0 3.75rem;
}
.filters {
  display: flex;
  padding-top: 1.25rem;
  gap: 3.125rem;
  justify-content: center;
  width: 100%;
}
.selectedFilter {
  user-select: none;
  font-size: var(--lg);
  color: var(--slate);
}
.selectedFilter:hover {
  color: var(--red);
}

.mobileSwiper {
  display: none;
}
.asideContent {
  font-size: var(--md);
  line-height: 140%;
  width: 50%;
  text-align: justify;
  color: black;
}
.viewMore {
  display: flex;
  font-size: var(--mid);
  cursor: pointer;
}
.viewMore p {
  border-bottom: 1px solid black;
}
/* Chnages from here */
.firmContent {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  padding: 6.25rem 0;
}
.firmContentItem {
  display: flex;
  gap: 2.125rem;
}
.firmContentItem:hover .firmContentItemHeading {
  color: var(--red);
}
.firmContentItemHeading {
  min-width: 49%;
  font-weight: 550;
  font-size: 2.813rem;
  transition: 0.1s all linear;
}
.firmContentItemContent {
  text-align: justify;
  font-size: var(--md);
  font-weight: 500;
  line-height: 140%;
  max-width: 50%;
}
.peopleContent {
  text-align: justify;
  padding-bottom: 9.375rem;
}

.heroItemsFirst {
  display: flex;
  gap: 0.625rem;
}
.heroItemsImg {
  min-width: 12.5rem;
  height: 15.625rem;
  background: var(--text-dim);
}
.heroItemsFirstHeading {
  font-size: var(--xxl);
}
.heroItemsFirstSubHeading {
  font-size: var(--mid);
  margin-bottom: 1.25rem;
}
.heroItemsArrowDiv {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  /* display: block; */
  /* background-color: red; */
}
.heroItemsArrowDivHero {
  display: block;
}
.heroItemsSecond {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  line-height: 140%;
  font-size: var(--md);
}
.peopleContentUpper {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  margin-bottom: 5rem;
}
.peopleContentLower {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contentUpperItem {
  display: flex;
  gap: 5rem;
}
.contentLowerItem {
  flex: 0 0 calc(50% - 40px);
  margin-bottom: 3.75rem;
}

.peopleContentHeroItems {
  /* width: 45.45%; */
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.peopleContentHeroItems:hover .heroItemsFirstHeading {
  color: var(--red);
}

.socialArrow {
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  min-width: 35px;
  border: 1.2px solid black;
  border-radius: 26px;
  transition: 0.3s all linear;
}
.socialArrow svg {
  position: relative;
  transition: 0.3s all ease-in-out;
}
.socialArrow:hover svg {
  transition: 0.3s all linear;
  left: 4px;
}
.swiperContainer {
  overflow: hidden;
}

#peopleDiv {

  /* display: none; */
}

@media screen and (max-width: 1345px) {
}

@media screen and (max-width: 1024px) {
  .root {
    gap: 1.875rem;
    padding: 0 1.25rem;
    margin-top: 20px;
  }

  .filters {
    display: flex;
    gap: 2.5rem;
    font-size: var(--mid);
  }
  .mobileSwiper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    max-height: 75dvh;
    overflow-y: scroll;
    scrollbar-width: thin; /* For Firefox */
  }

  .mobileSwiper::-webkit-scrollbar {
    display: none;
  }
  .heroItemsArrowDiv {
    display: block;
  }
  .asideContent {
    font-size: var(--mid);
    width: 100%;
    text-align: justify;
    color: black;
  }
  .firmContent {
    gap: 1.875rem;
    padding: 0.625rem 0;
  }
  .firmContentItem {
    flex-direction: column;
    gap: 1.25rem;
  }
  .firmContentItemHeading {
    width: 100%;
    letter-spacing: 0.3px;
    font-size: var(--md);
    color: var(--red);
  }
  .firmContentItemContent {
    font-size: var(--mid);
    font-weight: 400;
    line-height: 100%;
    max-width: 100%;
  }

  .peopleContent {
    padding: 2.5rem 0;
  }
  .heroItemsFirst {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .peopleContentUpper {
    flex-direction: column;
    gap: 2.5rem;
    margin-bottom: 5rem;
  }
  .heroItemsFirstHeading {
    font-size: var(--md);
    color: var(--red);
  }

  .heroItemsFirstSubHeading {
    font-size: var(--xs);
    margin-bottom: 0.625rem;
  }
  .contentLowerItem {
    flex: unset;
    margin-bottom: 2.5rem;
  }

  /* Orders */

  .filters {
    order: 1;
  }
  .asideContent {
    order: 2;
  }
  .firmContent {
    order: 3;
  }
  .viewMore {
    order: 4;
  }
  .swiperContainer {
    order: 5;
  }
  .peopleContent {
    order: 6;
  }
}

@media screen and (max-width: 576px) {
}
