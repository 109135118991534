.toggleSection {
  display: flex;
  gap: 3.125rem;
  justify-content: center;
  padding: 1.25rem 0;
}

.selectedFilter {
  user-select: none;
  font-size: var(--lg);
  color: var(--slate);
}

.activeSelectedFilter {
  color: var(--red);
  border-bottom: 3px solid var(--red);
}
.mainContent {
  display: flex;
}

.leftSection {
  max-height: 90dvh;
  max-width: 50%;
  overflow-y: scroll;
  margin-bottom: 10rem;
}
.leftSection::-webkit-scrollbar {
  display: none;
}
.newsItem {
  color: var(--slate);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;
  padding: 1.875rem 3.75rem;
  border-top: 1px solid var(--light-slate);
}
.newsItem:hover,
.newsItem:hover svg,
.newsItem:hover path {
  color: black;
  fill: black !important;
}
.miscDetails {
  min-width: 20%;
  font-size: var(--mid);
}
.newsContent {
  font-size: var(--md);
  margin-bottom: 0.625rem;
  text-align: justify;
}
.readMore {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.rightSection {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding: 3.75rem;
}
@media screen and (max-width: 1345px) {
}

@media screen and (max-width: 1024px) {
  .toggleSection {
    gap: 2.5rem;
    padding: 1.25rem 0;
  }

  .selectedFilter {
    font-size: var(--mid);
  }
  .mainContent {
    flex-direction: column;
  }

  .leftSection {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 6.25rem;
  }

  .newsItem {
    flex-direction: column;
    gap: 0.625rem;
    padding: 1.25rem;
  }

  .miscDetails {
    font-size: var(--xs);
  }
  .newsContent {
    font-size: var(--sm);
    margin-bottom: 0.625rem;
  }
  .rightSection {
    display: none;
  }
}

@media screen and (max-width: 576px) {
}
