.root {
  position: relative;
  background-color: var(--bg-primary);
  margin-top: 100dvh;
  z-index: 1;
}
section {
  padding: 2rem 3.75rem;
}
.section {
  display: flex;
  gap: 1.25rem;
  height: 100dvh;
}
.image {
  min-width: 70%;
  height: 85%;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aside {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 5px;
}

.asideTitle {
  color: var(--slate);
  font-size: var(--lg);
  font-weight: 400;
}

.asideHeadingContainer {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.asideHeading {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  line-height: 120%;
}
.dividerContainer {
  margin: 5rem 0;
  /* margin-bottom: 9.375rem; */
}
.dividerHeading {
  font-size: var(--xxxl);
  color: var(--red);
  margin-bottom: 1.25rem;
}

.carouselHeading,
.runningTextHeading {
  font-size: var(--xxxl);
  color: var(--slate);
  margin: 1.125rem auto;
  font-weight: 500;
  text-align: center;
}
.runningTextHeading {
  margin-bottom: 8rem;
}
.runningTextContainer {
  padding: 5rem 0;
}
.marqueeText {
  font-size: var(--xxxl);
  margin-right: 5rem;
}
.asideLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--lg);
  gap: 0.3rem;
  color: var(--slate);
  fill: #4f4f4f;
}
.asideLink:hover svg,
.asideLink:hover {
  fill: black;
  color: black;
}
@media screen and (max-width: 1345px) {
}

@media screen and (max-width: 1024px) {
  .root {
    position: relative;
    padding: 0 1.25rem;
    padding-top: 2rem;
    margin-top: 100dvh;
  }

  section {
    padding: 0;
  }

  .section {
    gap: 0.563rem;
    height: auto;
    flex-direction: column;
    margin-bottom: 6.25rem;
  }

  .image {
    width: 100%;
  }

  .aside {
    gap: 1.25rem;
  }

  .asideTitle,
  .asideLink {
    font-size: var(--sm);
  }
  .asideLink {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 0.3rem;
  }

  .asideHeadingContainer {
    gap: 0.825rem;
  }

  .asideHeading {
    font-size: var(--md);
    line-height: 110%;
  }
  .dividerHeading {
    font-size: var(--md);
  }
  .carouselHeading,
  .runningTextHeading {
    font-size: var(--md);
    margin: 1.875rem auto;
  }
  .marqueeText {
    font-size: var(--md);
  }

  .runningTextHeading {
    margin-bottom: 1.875rem;
  }
  .runningTextContainer {
    /* margin: 6.25rem 0; */
  }
  .runningTextContainer {
    padding: 5rem 0;
  }
}

@media screen and (max-width: 576px) {
}
