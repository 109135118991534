.root {
  padding-top: 3rem;
  padding-bottom: 9.375rem;
}
.filtersRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: 3.125rem;
}

.filters {
  display: flex;
  gap: 2.125rem;
  justify-content: center;
  width: fit-content;
}
.selectedFilter {
  user-select: none;
  font-size: var(--lg);
  color: var(--slate);
}
.secondaryFilter {
  user-select: none;
  font-size: var(--md);
  color: var(--slate);
  margin-right: 2px;
  /* background-color: red; */
}

.activeSelectedFilter {
  color: var(--red);
  border-bottom: 3px solid var(--red);
}
.activeSecondaryFilter {
  color: var(--red);
}
.activeSecondaryFilter::before {
  content: "\2022 ";
  margin-right: 3px;
  color: var(--red);
}

/* Dropdown, Mobile. */

.dropDownTitle {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: var(--md);
  color: var(--slate);
  margin-bottom: 0.625rem;
}
.dropDownContent {
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
}
.dropDownButtons {
  font-size: var(--md);
  color: var(--slate);
}

.switchView {
  display: flex;
  justify-content: flex-end;
  gap: 1.875rem;
  padding: 0 3.75rem;
  margin-bottom: 1.25rem;
  width: 100%;
}
.switchView span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.313rem;
  cursor: pointer;
  user-select: none;
}
.listItem:nth-child(1) {
  border-top: 1px solid var(--slate);
}
.listItem {
  position: relative;
  display: flex;
  gap: 3.125rem;
  border-bottom: 1px solid var(--slate);
  padding: 1.875rem 3.75rem;
  user-select: none;
  cursor: pointer;
}

.content,
.content_year {
  display: flex;
  justify-content: space-between;
  font-size: var(--lg);
  color: var(--slate);
}
.content_year {
  width: 49px;
}
.listItem:hover .content,
.listItem:hover .content_year {
  color: black;
}

.hoverImage {
  position: absolute;
  top: 100%;
  left: 80%;
  z-index: 2000;
  transform: translate(-50%, -80%);
  width: 20%;
}
.hoverImage img {
  max-width: 100%;
}
/* GRID */
.gridView {
  display: flex;
  flex-wrap: wrap;
  padding: 0 3.625rem;
  justify-content: space-between;
}

.gridItem {
  cursor: pointer;
  width: calc(50% - 3rem);
  margin-bottom: 3.75rem;
  box-sizing: border-box;
}

.gridItem:hover .content,
.gridItem:hover .content_year {
  color: black;
}

.image {
  height: 28rem;
  margin-bottom: 0.625rem;
  transition: all 0.3s ease;
  /* filter: grayscale(100%) sepia(1) hue-rotate(-20deg); */
  filter: grayscale(100%);
}
.imageCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image img {
  height: 100%;
  width: 100%;
}

.image:hover {
  filter: none;
  transform: scale(1.01);
}

@media screen and (max-width: 1145px) {
  /* It looks bad in these screens */

  .image {
    height: auto;
    /* change the height. */
    margin-bottom: 0.625rem;
  }
}

@media screen and (max-width: 1024px) {
  .root {
    padding-top: 1rem;
  }
  .filtersRoot {
    align-items: flex-start;
    padding: 0 1.25rem;
  }

  .secondaryFilter {
    width: calc(48%);
    margin-bottom: 1rem;
  }
  .filters {
    flex-wrap: wrap;
    gap: 1px;
  }
  .listItem {
    gap: 1.25rem;
    padding: 1rem 1.25rem;
  }
  .content,
  .content_year {
    font-size: var(--md);
  }

  .gridView {
    padding: 0 1.25rem;
  }

  .gridItem {
    width: 100%;
    margin-bottom: 3.125rem;
  }
  .image {
    height: auto;
  }

  .switchView {
    padding: 0 1.75rem;
  }
}

@media screen and (max-width: 576px) {
}
