@font-face {
  font-family: "caldina";
  src: url("./assets/fonts/caldina-regular.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "caldina";
  box-sizing: border-box;
  line-height: 140% !important;
  /* outline: 2px solid black; */
}

:root {
  --xxs: 0.75rem; /* 12 pixels */
  --xs: 0.9rem; /* 14 pixels */
  --sm: 1rem; /* 16 pixels */
  --mid: 1.125rem; /* 18 pixels */
  --md: 1.25rem; /* 20 pixels */
  --lg: 1.5rem; /* 24 pixels */
  --xl: 1.75rem; /* 28 pixels */
  --xxl: 2rem; /* 32 pixels */
  --xxxl: 2.25rem; /* 36 pixels */

  --red: #9b1c1f;
  --white: #ffffff;

  --text-dim: #acacac;
  --text-light: #ffffff;
  --offwhite: #f8f8f8;
  --text-menu: #d6d6d6;
  --light-slate: #7b7b7b;
  --bg-landing: #2f2f2f;
  --slate: #4f4f4f;
  --light-grey: #e6e6e6;
  --bg-primary: #d6d6d6;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #d6d6d6;
}

*::-webkit-scrollbar {
  width: 4px;
  background-color: #d6d6d6;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;

  background-color: #555;
}

html {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

a {
  color: unset;
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
img {
  box-shadow: -12px 12px 80px 0px rgba(31, 36, 45, 0.25);
}
.img-noshadow {
  box-shadow: 0px 0px 0px 0px rgba(31, 36, 45, 0);
}

label,
button,
select,
summary,
[type="radio"],
[type="submit"],
[type="checkbox"] {
  cursor: pointer;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

button {
  background-color: inherit;
  cursor: pointer;
}

/* Custom classes */

/* Burger menu global styles */
#react-burger-menu-btn {
  height: 22px !important;
  width: 22px !important;
}
.bm-burger-button {
  position: fixed;
  right: 3.75rem;
  top: 1.875rem;
}

.bm-cross-button {
  margin-right: 3.75rem;
  margin-top: 2.188rem;
}

.bm-menu {
  background: var(--slate);
  height: 100vh !important;
  /* TEST */
  padding: 3.75rem;
  color: var(--text-menu);
  overflow: hidden !important;
}

.bm-menu-wrap {
  top: 0;
}
.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
  color: var(--text-menu);
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 4.375rem;
}

/* Menu Overlay */
.bm-overlay {
  display: none;
}

@media screen and (max-width: 576px) {
  .logo {
    width: 60%;
  }
  .root {
    padding: 1.25rem;
  }

  .bm-burger-button {
    right: 1.625rem;
    top: 1.625rem;
  }

  .bm-cross-button {
    margin-right: 1.25rem;
    margin-top: 1.56rem;
  }
  .bm-item-list {
    align-items: center;
  }
}

/* For Swiper */
.swiper {
  overflow: unset !important;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute !important;
  transform: scale(0.7) !important;
  top: initial !important;
  bottom: -15% !important;
  z-index: 999999;
  color: var(--slate) !important;
}
.swiper-button-next {
  right: 47% !important;
}

.swiper-button-prev {
  left: 47% !important;
}

@media screen and (max-width: 1024px) {
  .swiper-button-next {
    right: 38% !important;
    bottom: -25% !important;
  }

  .swiper-button-prev {
    left: 38% !important;
    bottom: -25% !important;
  }
}

/* Progress bar */

.progress-bar {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 1px;
  height: 4px;
  background: var(--red);
  transform-origin: 0%;
}

/* Chevrons */

#chevron-arrow-down {
  display: inline-block;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  margin: 0 20px;
  width: 10px;
  height: 10px;
  margin-bottom: 5px;
  transform: rotate(-315deg);
}

#chevron-arrow-up {
  display: inline-block;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  margin: 0 20px;
  width: 10px;
  height: 10px;
  transform: rotate(-135deg);
}

.scrollBody {
  width: 100vw;
  position: fixed;
  top: 0;

  display: flex;
  flex-direction: column;
}

.smaller {
  transition: all 0.6s ease-in-out;
}

.smaller img {
  margin-left: 22px;
  transform: scale(1.2) !important;
}
@media screen and (max-width: 1024px) {
  .smaller img {
    margin-left: 0;
  }
}
@media screen and (max-width: 500px) {
  .smaller img {
    margin-left: 0px;
    transform: scale(1.1) !important;
  }
}
.red-span {
  color: var(--red);
}

/* Right Arrow */

#socialArrow svg {
  position: relative;
  transition: 0.3s all ease-in-out;
}
#socialArrow:hover svg {
  transition: 0.3s all linear;
  left: 4px;
}

/* Image filter */

.image-link {
  transition: all 0.3s ease;
  /* filter: grayscale(100%) sepia(1) hue-rotate(-20deg); */
  filter: grayscale(100%);
}
.image-link:hover {
  filter: none;
  transform: scale(1.01);
}
