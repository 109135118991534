.root {
  height: 100%;
  width: 100%;
}
.staticBg {
  position: fixed;
  height: calc(100vh - 3.8rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.storiesHeading {
  font-size: 2.813rem;
  color: var(--red);
  font-weight: 500;
}
.content {
  position: relative;
  padding: 12.5rem;
  z-index: 1000;
  /* glass */
  /* Glass effect */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.194);
}
.image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.contentof {
  max-width: 100%;
}
.stories {
  text-align: center;
  font-size: var(--xxxl);
  padding: 3rem;
  color: var(--red);
}
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Adjust as needed */
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 2rem;

  width: fit-content;
  margin-top: 4rem;
}
.modalBody {
  max-width: fit-content;
}
.modalText {
  text-align: center;
  width: 80%;
}
.modalImage {
  max-height: 50dvh;
  object-fit: cover;
}
.modalHeader {
  width: fit-content;
}

.closeButton {
  display: flex;
  background-color: var(--light-grey);
  border-radius: 100%;
  padding: 0.625rem;
  color: black;
  transition: box-shadow 0.3s ease;
}

.closeButton:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Add additional styling as per your requirements */
@media screen and (max-width: 1345px) {
}

@media screen and (max-width: 1024px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 1.25rem;
    margin-top: 50dvh;
  }
  .modalContent {
    padding: 0.5rem;
  }
  .image {
    min-width: 100%;
  }
  .modalImage {
    max-height: 40dvh;
    object-fit: cover;
  }
  .modalText {
    text-align: center;
    width: 70%;
  }
}

@media screen and (max-width: 576px) {
}
