.loadingScreenWhite,
.loadingScreenBlack {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d6d6d6; /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 4.25rem;
}
.loadingScreenBlack {
  background-color: #2f2f2f; /* Semi-transparent background */
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db; /* Change color as needed */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .loadingScreenWhite,
  .loadingScreenBlack {
    padding: 0 1.3rem;
  }
}
