.root {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.875rem 3.75rem;
  height: 5rem;
  background-color: inherit;
  color: var(--light-slate);
  z-index: 999999;
  transition: all 0.2s linear;

  /* Glass effect */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.194);
}

.logo {
  position: relative;
  width: 15%;
}

.logo img {
  width: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  top: 0;

  /* left: 20px; */
}
.logo img:nth-child(2) {
  position: absolute;
  opacity: 0;
}
.logo:hover img:nth-child(1) {
  opacity: 0;
}

.logo:hover img:nth-child(2) {
  opacity: 1;
}

/* Animations */
/* Menu item on hover  */

.menuItem {
  display: inline-block;
  padding-bottom: 0.25rem;
  position: relative;
  font-size: var(--xxl);
}

.menuItem::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--text-menu);
  transition: all 0.25s ease-out;
}
.menuItem:hover::before {
  width: 100%;
}
.navItems {
  display: flex;
  justify-content: space-evenly;
  font-size: var(--md);
}

.navItem {
  margin-left: 2.75rem;
}
@media screen and (max-width: 1345px) {
  .root {
    padding: 1.85rem 3.725rem;
  }
  .menuItem {
    font-size: var(--lg);
  }
}

@media screen and (max-width: 1024px) {
  .logo {
    position: relative;
    width: 25%;
  }

  .menuItem {
    font-size: var(--lg);
  }
}

@media screen and (max-width: 576px) {
  .logo {
    position: absolute;
    width: 50%;
    left: 1.625rem;
  }

  .menuItem {
    font-size: var(--xxl);
  }
}
