/* 1440 1024 */
.root {
  height: 100dvh;
  min-width: 100%;
  background-color: var(--bg-landing);
  padding: 3.75rem;
  position: fixed;
  z-index: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.75rem;
  width: 100%;
}
.logo {
  width: 30%;
}
.navigation {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  font-size: var(--lg);
  color: var(--text-dim);
}
.navigationItems {
  transition: all 0.2s linear;
}
.navigationItems:hover {
  color: var(--text-light);
}
.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  min-width: 100%;
  overflow: hidden;
  margin-bottom: 3rem;
}

.frame svg,
.frame img {
  min-width: 100%;
}
.arrow {
  text-align: center;
}

@media screen and (max-width: 1345px) {
  .navigation {
    width: 40%;
    font-size: var(--md);
  }
  .root {
    padding: 3.25rem;
  }
  .header {
    margin-bottom: 3.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .navigation {
    width: 60%;
  }
  .root {
    height: 100dvh;
    min-width: 100%;
    background-color: var(--bg-landing);
    padding: 3.75rem;
    position: fixed;
    z-index: 0;
  }
  .root > svg {
    display: block;
    width: auto;
    height: 100dvh;
  }
}

@media screen and (max-width: 576px) {
  /* Your smartphone styles here */
}
