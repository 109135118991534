.root {
  padding: 3.75rem;
  padding-bottom: 9.375rem;
  color: var(--offwhite);
  background-color: var(--bg-landing);
}
.upperDiv {
  display: flex;
  justify-content: space-between;
  gap: 4.75rem;
}
.upperContent {
  font-size: var(--xxl);
}
.formContent,
.sideContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3.75rem;
  min-height: 100%;
}
.formContent {
  min-width: 55%;
}
.or {
  font-size: var(--md);
  text-align: center;
}
.email {
  font-size: var(--lg);
}
.formOuter {
  display: flex;
  flex-direction: column;
}
.form {
  width: 100%;
  display: flex;
  gap: 3.125rem;
}

.carrer {
  font-size: var(--xxl);
}
.formInput,
.textArea {
  all: unset;
  width: 100%;
  font-size: var(--md);
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--offwhite);
}
.asideTextarea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
}
.textArea,
.sendBtn {
  max-width: 98%;
  padding: 0.375rem;
  border: 1px solid var(--offwhite);
}
.sendBtn {
  color: var(--offwhite);
  padding: 0.5rem 0.938rem;
}
.lowerDiv {
  display: flex;
  align-items: center;
  gap: 3.125rem;
}
@media screen and (max-width: 1345px) {
}

@media screen and (max-width: 1024px) {
  .root {
    padding: 1.25rem;
  }
  .upperDiv {
    flex-direction: column;
    gap: 1.25rem;
  }
  .upperContent {
    font-size: var(--lg);
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0.3px;
  }
  .email,
  .or {
    text-align: center;
    font-size: var(--md);
  }
  .email {
    text-align: left;
  }
  .carrer {
    text-align: left;
    font-size: var(--md);
    margin-bottom: 1.875rem;
  }
  .formContent,
  .form {
    gap: 1.25rem;
    flex-direction: column;
    margin-bottom: 2.5rem;
  }

  .form {
    gap: 0rem;
  }

  .formInput,
  .textArea {
    font-size: var(--mid);
    padding: 0.3rem 0;
    margin-bottom: 1.25rem;
  }
  .textArea {
    padding-right: 0.3rem;
    padding-left: 0.4rem;
  }
  .sendBtn {
    font-size: var(--mid);
  }
}

@media screen and (max-width: 576px) {
}
